<template>
  <tour :steps="tourSteps">
  <overlay
    :show="loadingShow"
    class-name="content-wrapper"
  >
    <PageHeader
      screen-name="Adicionar usuário"
      :link-items="linkItems"
    />

    <ConfirmModal
      item-identifier="usuário"
      :modal-show="modal.showModal"
      :type-modal="modal.typeModal"
      @confirmed="redirectToList"
      @canceled="closeModalConfirm"
    />

    <ActionColaboradorModal
      item-identifier="usuário"
      :modal-show="modalCpf.showModal"
      :type-modal="modalCpf.typeModal"
      @confirmed="closeModalCpf"
    />

    <SucessoModal
      :modal-show="modalSucesso.showModal"
      :type-modal="modalSucesso.typeModal"
      :case-modal="modalSucesso.caseModal"
      @confirmed="redirectToList"
    />
    <div id="empresa">
    <main class="card">
      <HeaderEmpresa
        :empresa="empresa"
        @confirmed="resolveRoute"
      />
      <section class="form p-2">
        <validation-observer ref="usuarioRules">
          <b-form>
            <b-row>
              <b-col
                lg="4"
                md="4"
                sm="12"
              >
                <b-form-group
                  label="CPF*"
                  label-for="cpf"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="CPF"
                    rules="required|cpf"
                  >
                    <b-form-input
                      id="cpf"
                      v-model="form.cpf"
                      v-mask="'###.###.###-##'"
                      placeholder="000.000.000-00"
                      autocomplete="off"
                      @input="returnPessoa"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                lg="4"
                md="4"
                sm="12"
              >
                <b-form-group label="Nome completo do colaborador*">
                  <validation-provider
                    #default="{ errors }"
                    name="nome"
                    rules="required"
                  >
                    <b-form-input
                      id="nome-input"
                      v-model="form.nome"
                      :state="errors.length > 0 || form.errorApi.nome || form.errorApi.error ? false:null"
                      placeholder="John Doe"
                      :disabled="form.disabled"
                    />
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >
                      Este campo é de preenchimento obrigatório.
                    </small>
                    <small
                      v-if="form.errorApi.nome"
                      class="text-danger"
                    >
                      {{ form.errorApi.nome }}
                    </small>
                    <small
                      v-if="form.errorApi.error"
                      class="text-danger"
                    >
                      {{ form.errorApi.error }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                lg="4"
                md="4"
                sm="12"
              >
                <validation-provider
                  #default="{ errors }"
                  name="sexo"
                  rules="required"
                >
                  <b-form-group
                    label="Sexo*"
                    :state="errors.length > 0 ? false:null"
                  >
                    <v-select
                      id="sexo"
                      v-model="form.sexo"
                      :options="generoSelect"
                      label="topico"
                      placeholder="Selecione uma opção"
                      :clearable="true"
                      :disabled="disabled.genero"
                    />
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >
                      Este campo é de preenchimento obrigatório.
                    </small>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4" xl="4" sm="12">
                <validation-provider name="nome_mae" #default="{ errors }">
                  <b-form-group label="Nome da mãe" placeholder="Nome da mãe">
                    <b-form-input
                      :state="
                        errors.length > 0 || form.errorApi.nome_mae ? false : null
                      "
                      v-model="form.nome_mae"
                      id="nome_mae"
                      name="nome_mae"
                      placeholder="Nome da mãe"
                      :disabled="disabled.nome_mae"
                    />
                    <small v-if="form.errorApi.nome_mae" class="text-danger">
                      {{ form.errorApi.nome_mae }}
                    </small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                lg="4"
                md="4"
                sm="12"
              >
                <b-form-group label="Data de nascimento*">
                  <validation-provider
                    #default="{ errors }"
                    name="data_nascimento"
                    rules="required"
                  >
                    <b-input-group>
                      <vue-cleave
                        id="data-nascimento-input"
                        v-model="input.data_nascimento"
                        :raw="false"
                        :options="option.data"
                        class="form-control"
                        placeholder="00/00/0000"
                        name="data_nascimento"
                        autocomplete="off"
                        :disabled="form.disabled"
                        @input="setNascimentoInput"
                      />
                      <b-input-group-append>
                        <b-form-datepicker
                          v-model="form.data_nascimento"
                          locale="pt-BR"
                          button-only
                          right
                          :hide-header="true"
                          :max="today"
                          class="form-control d-flex align-items-center"
                          label-help="Selecione a data de nascimento"
                          button-variant="custom-blue"
                          clearable
                          :disabled="form.disabled"
                          @input="setNascimentoPicker"
                        />
                      </b-input-group-append>
                    </b-input-group>

                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >
                      Este campo é de preenchimento obrigatório.
                    </small>
                    <small
                      v-if="form.errorApi.data_nascimento"
                      class="text-danger"
                    >
                      {{ form.errorApi.data_nascimento }}
                    </small>
                    <small
                      v-if="form.errorApi.error"
                      class="text-danger"
                    >
                      {{ form.errorApi.error }}
                    </small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                lg="4"
                md="4"
                sm="12"
              >
                <b-form-group
                  label="E-mail*"
                  label-for="email"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="E-mail"
                    rules="required|email"
                  >
                    <b-form-input
                      id="email"
                      v-model="form.email"
                      placeholder="johndoe@gmail.com"
                      autocomplete="off"
                      type="email"
                      :disabled="form.disabledMail"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4" xl="4" class="mb-1">
                <NacionalidadeField
                  :key="form.id_nacionalidade"
                  :idNacionalidade="form.id_nacionalidade"
                  @updateNacionalidade="updateNacionalidade"
                  :errorApiIdNacionalidade="form.errorApi.id_nacionalidade"
                  customClass="register-company-user"
                  :isDisabled="disabled.nacionalidade"
                />
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </section>
      <section class="buttons p-2">
        <b-button
          id="save-colaborador"
          class="mr-2 mb-2 mb-sm-0"
          variant="custom-blue"
          :disabled="saving || savingAndNew"
          @click.prevent="validationForm(true)"
        >
          <feather-icon
            icon="CheckIcon"
            class="mr-50"
          />
          <span class="align-middle">Salvar usuário</span>
          <span
            v-if="saving"
            class="spinner-border spinner-border-sm ml-1"
            role="status"
            aria-hidden="true"
          />
        </b-button>
        <b-button
          id="save-new-colaborador"
          class="mr-2 mb-2 mb-sm-0"
          variant="custom-blue"
          :disabled="saving || savingAndNew"
          @click.prevent="validationForm(false)"
        >
          <feather-icon
            icon="CheckIcon"
            class="mr-50"
          />
          <span class="align-middle">Salvar e cadastrar um novo</span>
          <span
            v-if="savingAndNew"
            class="spinner-border spinner-border-sm ml-1"
            role="status"
            aria-hidden="true"
          />
        </b-button>
        <b-button
          id="cancel-register-colaborador"
          :disabled="saving || savingAndNew"
          variant="outline-danger"
          @click.prevent="openModalConfirm"
        >
          <feather-icon
            icon="XIcon"
            class="mr-50"
          />
          <span class="align-middle">Cancelar</span>
        </b-button>
      </section>
    </main>
    </div>
  </overlay>
</tour>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BFormDatepicker, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, cpf, email } from '@validations'
import vSelect from 'vue-select'
import moment from 'moment'
import VueCleave from 'vue-cleave-component'
import { getIdEmpresa } from '@core/utils/store/getStore'
import { setIdEmpresa } from '@core/utils/store/setStore'
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue'
import ConfirmModal from '@/views/components/custom/modals/ConfirmModal.vue'
import SucessoModal from '@/views/components/custom/modals/SucessoCriacaoEdicaoModal.vue'
import ActionColaboradorModal from '@/views/components/custom/modals/ActionsColaboradorModal.vue'
import HeaderEmpresa from '@/views/components/custom/page-header/Header-empresa.vue'
import handleErrorsService from './Services/HandleErrors'
import { createCompanyUser } from '@/views/custom-pages/gerenciarUsuarios/requests'
import Overlay from '@/views/components/custom/overlay/Overlay.vue'
import { warningInfoMessage, warningMessage } from '@/libs/sweetalerts'
import {strClear} from "@core/utils/utils";
import NacionalidadeField from "@/views/components/custom/field-select-nacionalidade/NacionalidadeField.vue";
import tourMixin from '@/mixins/tourMixin'
import colaboradorTourMixin from '../colaborador/mixins/colaboradorTourMixin'

export default {
  title: 'Cadastrar usuário na empresa',
  components: {
    Overlay,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    PageHeader,
    ConfirmModal,
    SucessoModal,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
    HeaderEmpresa,
    VueCleave,
    ActionColaboradorModal,
    NacionalidadeField,
  },
  mixins: [tourMixin, colaboradorTourMixin],
  data() {
    return {
      loadingShow: true,

      required,
      cpf,
      email,

      linkItems: [
        {
          name: 'Empresa',
          routeName: 'empresa-usuario-list',
        },
        {
          name: 'Carregando dados...',
          active: true,
        },
        {
          name: 'Adicionar usuário',
          active: true,
        },
      ],
      modal: {
        showModal: false,
        typeModal: 'danger',
      },
      modalSucesso: {
        showModal: false,
        typeModal: 'success',
        caseModal: 'criacao',
      },
      modalCpf: {
        showModal: false,
        typeModal: 'custom-blue',
      },
      savedAlert: false,
      saving: false,
      savingAndNew: false,
      today: moment().format('YYYY-MM-DD'),
      option: {
        data: {
          date: true,
          delimiter: '/',
          datePattern: ['d', 'm', 'Y'],
        },
        cpf: {
          numericOnly: true,
          uppercase: true,
          delimiters: ['.', '.', '-'],
          blocks: [3, 3, 3, 2],
        },
      },
      disabled: {
        nome_mae: false,
        nacionalidade: false,
        genero: false,
      },
      form: {
        disabled: false,
        disabledMail: false,
        cpf: '',
        nome: '',
        sexo: '',
        data_nascimento: '',
        email: '',
        nome_mae: '',
        id_nacionalidade: null,
        errorApi: {
          cpf: '',
          nome: '',
          sexo: '',
          data_nascimento: '',
          email: '',
          error: '',
          nome_mae: "",
          id_nacionalidade: "",
        },
      },
      input: {
        data_nascimento: '',
      },
      generoSelect: [],
      empresa: {
        id_empresa: '',
        nome: '',
        gerenciarColaboradores: false,
      }
    }
  },

  mounted() {
    if(this.loadData) {
      this.mountedInit()
    }
  },
  methods: {
    mountedInit() {
      if ((getIdEmpresa() === null || typeof getIdEmpresa() === 'undefined') && typeof this.$route.params.idEmpresa === 'undefined') {
      this.openModalError()
      return
    }

    if (typeof this.$route.params.idEmpresa !== 'undefined') {
      if (this.$route.params.idEmpresa !== getIdEmpresa()) {
        setIdEmpresa(this.$route.params.idEmpresa)
      }
    }

    this.generoSelect = ['Masculino', 'Feminino']

    this.handleGetCompany()
    },
    async handleGetCompany() {
      this.loadingShow = true

      await this.$http.get(this.$api.getShowAndEditEmpresa(getIdEmpresa())).then(({ data }) => {
        this.empresa.id_empresa = data.id_empresa
        this.empresa.nome = data.cnpj ? data.nome_fantasia : data.nome
        this.empresa.gerenciarColaboradores = data.pode_gerenciar_colaboradores
        this.linkItems[1].name = this.empresa.nome
      })

      this.loadingShow = false
    },

    validationForm(redirectToList) {
      this.$refs.usuarioRules.validate().then(success => {
        if (success) {
          this.saving = true
          this.saveColaborador(redirectToList)
        }
      })
    },
    async saveColaborador(redirectToList) {
      this.loadingShow = true

      const formData = this.prepareDataToSave()

      await createCompanyUser(formData)
        .then(() => {
          if (redirectToList) {
            this.openModalSucesso()
          } else {
            this.savedAlert = !this.savedAlert
          }

          this.saving = false
          this.savingAndNew = false
          this.cleanForm()
        }).catch(error => {
          this.handleError(error.response)

          this.saving = false
          this.savingAndNew = false
        })

      this.loadingShow = false
    },
    prepareDataToSave() {
      return {
        nome: this.form.nome,
        cpf: strClear(this.form.cpf),
        dataNascimento: this.form.data_nascimento,
        sexo: this.form.sexo,
        email: this.form.email,
        empresasId: this.empresa ? [this.empresa.id_empresa] : null,
        empresaUnica: true,
        nomeMae: this.form.nome_mae,
        idNacionalidade: this.form.id_nacionalidade,
      }
    },

    handleError(response) {
      const errors = response.status === 400 || response.status === 404

      if (errors) {
        warningInfoMessage(
          response.data.errors,
          'Confira os dados e depois salve o usuário.',
          'Entendido',
        )

        return
      }

      warningMessage('Não foi possível realizar a sua solicitação.<br /> Entre em contato com o suporte.')
    },

    cleanForm() {
      this.form.cpf = ''
      this.form.nome = ''
      this.form.sexo = ''
      this.form.data_nascimento = ''
      this.input.data_nascimento = ''
      this.form.email = ''
      this.form.nome_mae = "";
      this.form.id_nacionalidade = null;

      this.form.errorApi.cpf = ''
      this.form.errorApi.nome = ''
      this.form.errorApi.sexo = ''
      this.form.errorApi.data_nascimento = ''
      this.form.errorApi.nome_mae = "";
      this.form.errorApi.id_nacionalidade = "";

      this.$refs.usuarioRules.reset()
    },
    returnPessoa() {
      if (this.form.cpf.length === 14) {
        this.form.disabled = true

        this.$http.get(this.$api.getPessoaFisicaPorCpf(strClear(this.form.cpf))).then(data => {
          const pessoa = data.data
          if (data.status === 200) {
            this.openModalCpf()
            this.form.nome = pessoa.nome
            this.form.sexo = pessoa.genero
            this.form.data_nascimento = pessoa.data_nascimento
            this.form.nome_mae = pessoa.nome_mae
            this.form.id_nacionalidade = pessoa.id_nacionalidade
            this.form.email = pessoa.usuario ? pessoa.usuario.email : ''

            this.form.disabledMail = !!this.form.email
            this.disabled.nome_mae = !!pessoa.nome_mae
            this.disabled.nacionalidade = !!pessoa.id_nacionalidade
            this.disabled.genero = !!pessoa.genero

            this.setNascimentoPicker()
          } else {
            this.form.disabled = false
            this.form.disabledMail = false
          }
        }).catch(({ response: { data } }) => {
          handleErrorsService.handleErrorsCompanyUser(data, this.form.errorApi)
          this.form.disabled = false
          this.form.disabledMail = false
        })
      } else {
        this.form.disabled = false
        this.form.disabledMail = false
        this.form.nome = ''
        this.form.sexo = ''
        this.form.data_nascimento = ''
        this.input.data_nascimento = ''
        this.form.nome_mae = null
        this.form.id_nacionalidade = null
      }
    },
    setNascimentoPicker() {
      this.input.data_nascimento = moment(this.form.data_nascimento).format('DD/MM/YYYY')
    },
    setNascimentoInput() {
      this.form.data_nascimento = moment(this.input.data_nascimento, 'DD/MM/YYYY').format('YYYY-MM-DD')
    },

    redirectToList() {
      this.$router.push({ name: 'empresa-usuario-list' })
    },
    openModalConfirm() {
      this.modal.showModal = true
    },

    closeModalConfirm() {
      this.modal.showModal = false
    },

    openModalSucesso() {
      this.modalSucesso.showModal = true
    },
    openModalCpf() {
      this.modalCpf.showModal = true
    },
    closeModalCpf() {
      this.modalCpf.showModal = false
    },

    resolveRoute(route) {
      if (route !== this.$route.name) {
        this.$router.push(
          {
            name: route,
            params: {
              empresa: this.empresa,
            },
          },
        )
      }
    },
    updateNacionalidade(id_nacionalidade) {
      this.form.id_nacionalidade = id_nacionalidade;
    },
  },
}
</script>
